import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';

const NotFound = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-darkbg text-white p-8">
      <h1 className="text-6xl font-bold mb-4">404</h1>
      <h2 className="text-2xl mb-8">Página no encontrada</h2>
      <p className="text-gray-400 mb-8 text-center max-w-md">
        Lo sentimos, la página que estás buscando no existe o ha sido movida.
      </p>
      <Link to="/">
        <Button variant="outline" className="text-white border-neon-green hover:bg-neon-green/10">
          Volver al inicio
        </Button>
      </Link>
    </div>
  );
};

export default NotFound;