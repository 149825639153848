
import React, { useEffect, useRef, useState } from 'react';
import { cn } from '@/lib/utils';
import NeonLines from '../NeonLines';
import { Button } from '@/components/ui/button';
import { Search } from 'lucide-react';

const HeroSection: React.FC = () => {
  const sectionRef = useRef<HTMLElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [aiResponse, setAiResponse] = useState('');

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('revealed');
          }
        });
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const scrollToContact = () => {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      window.scrollTo({
        top: contactSection.offsetTop - 100,
        behavior: 'smooth',
      });
    }
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (!searchValue.trim()) return;

    // Simulate search results
    const mockResults = [
      'Diseño web personalizado',
      'Desarrollo de aplicaciones móviles',
      'Marketing digital y estrategia SEO',
      'Consultoría técnica en desarrollo'
    ];
    
    setSearchResults(mockResults);
    
    // Simulate AI response
    setAiResponse(`WebStudioExpress ofrece soluciones personalizadas de ${searchValue} adaptadas específicamente a las necesidades de tu negocio. Contamos con profesionales especializados y una metodología centrada en resultados. Contacta con nosotros para un presupuesto sin compromiso.`);
    
    setShowResults(true);
  };

  return (
    <section 
      id="home" 
      ref={sectionRef}
      className="section-reveal min-h-screen flex items-center relative overflow-hidden pt-20"
    >
      <NeonLines />
      
      <div className="container mx-auto px-6 z-10">
        <div className="flex flex-col items-center text-center">
          <div className="max-w-2xl mx-auto mb-12">
            <h1 className="text-4xl md:text-6xl font-bold mb-6">
              <span className="block text-white">Soluciones</span>
              <span className="block text-neon-cyan neon-text-cyan">de Software</span>
              <span className="block text-white">Para Tu Negocio</span>
            </h1>
            
            <p className="text-gray-300 text-lg mb-8 max-w-xl mx-auto">
              Creamos software personalizado, aplicaciones web y móviles que transforman tus ideas en potentes experiencias digitales.
            </p>
            
            <div className="flex flex-wrap gap-4 justify-center">
              <Button 
                onClick={scrollToContact}
                className="bg-neon-purple hover:bg-neon-purple/80 text-white px-8 py-3 rounded-md neon-border"
              >
                Comenzar
              </Button>
              
              <Button 
                variant="outline" 
                className="border-neon-cyan text-neon-cyan hover:bg-neon-cyan/10"
                onClick={() => document.getElementById('services')?.scrollIntoView({ behavior: 'smooth' })}
              >
                Nuestros Servicios
              </Button>
            </div>
          </div>
          
          {/* Buscador con estilo neón */}
          <div className="mt-10 w-full max-w-xl">
            <form onSubmit={handleSearch} className="relative">
              <div className="relative">
                <input
                  type="text"
                  placeholder="¿Qué servicio estás buscando?"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  className="w-full bg-transparent text-white py-3 px-4 focus:outline-none border-b-2 border-neon-purple rounded-b-[60px] placeholder-gray-400"
                />
                <Button 
                  type="submit"
                  variant="ghost" 
                  size="icon"
                  className="absolute right-0 top-0 h-full text-neon-purple"
                >
                  <Search className="h-5 w-5" />
                </Button>
                
                {/* Animación de borde neón */}
                <div className="absolute bottom-0 left-0 right-0 h-[2px] overflow-hidden">
                  <div className="h-full w-full bg-gradient-to-r from-neon-purple via-neon-cyan to-neon-green animate-gradient-x rounded-b-[60px]"></div>
                </div>
              </div>
              
              {/* Resultados de búsqueda */}
              {showResults && (
                <div className="mt-4 bg-black/70 backdrop-blur-sm border border-[#bc13fe] rounded-lg p-4 text-white">
                  <h3 className="text-lg font-semibold mb-2 text-neon-purple">Resultados:</h3>
                  <ul className="mb-4 space-y-1">
                    {searchResults.map((result, index) => (
                      <li key={index} className="py-1 hover:text-neon-cyan cursor-pointer">{result}</li>
                    ))}
                  </ul>
                  
                  <div className="border-t border-white/10 pt-4 mt-4">
                    <h4 className="text-neon-cyan font-medium mb-2">Respuesta IA:</h4>
                    <p>{aiResponse}</p>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
