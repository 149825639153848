import React, { useState } from 'react';
import { useToast } from '@/components/ui/use-toast';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Phone, Mail, MapPin, Clock, PhoneCall } from 'lucide-react';

const ContactSection = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    service: '',
    message: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          access_key: 'a0da5ffe-3cfa-4364-a674-1862681fa399',
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          service: formData.service,
          message: formData.message,
          from_name: 'WebStudioExpress Contact Form',
          subject: 'Nuevo mensaje de contacto'
        }),
      });

      const data = await response.json();

      if (data.success) {
        toast({
          title: '¡Mensaje enviado!',
          description: 'Gracias por contactarnos. Nos pondremos en contacto contigo pronto.',
        });
        setFormData({
          name: '',
          email: '',
          phone: '',
          service: '',
          message: ''
        });
      } else {
        throw new Error(data.message || 'Error al enviar el mensaje');
      }
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'Error',
        description: 'Hubo un problema al enviar tu mensaje. Por favor, inténtalo de nuevo.',
        variant: 'destructive',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id="contact" className="py-20 relative bg-darkbg/90">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            <span className="text-white">Contáctanos</span>
          </h2>
          <p className="text-gray-300 max-w-2xl mx-auto">
            ¿Listo para llevar tu proyecto al siguiente nivel? Estamos aquí para ayudarte.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div className="space-y-8">
            <div className="bg-darkbg/70 p-8 rounded-xl border border-gray-800">
              <h3 className="text-2xl font-bold text-white mb-6">Información de Contacto</h3>
              
              <div className="space-y-6">
                <div className="flex items-start gap-4 group">
                  <MapPin className="w-6 h-6 text-neon-purple flex-shrink-0 mt-1 group-hover:text-neon-purple/80 transition-colors" />
                  <div>
                    <h4 className="text-white font-medium mb-1">Dirección</h4>
                    <p className="text-gray-400 group-hover:text-neon-purple/80 transition-colors">Puebla, México</p>
                  </div>
                </div>

                <div className="flex items-start gap-4 group">
                  <PhoneCall className="w-6 h-6 text-neon-cyan flex-shrink-0 mt-1 group-hover:text-neon-cyan/80 transition-colors" />
                  <div>
                    <h4 className="text-white font-medium mb-1">Teléfono</h4>
                    <p className="text-gray-400 group-hover:text-neon-cyan/80 transition-colors">+52 221 862 8551</p>
                  </div>
                </div>

                <div className="flex items-start gap-4 group">
                  <Mail className="w-6 h-6 text-neon-green flex-shrink-0 mt-1 group-hover:text-neon-green/80 transition-colors" />
                  <div>
                    <h4 className="text-white font-medium mb-1">Email</h4>
                    <p className="text-gray-400 group-hover:text-neon-green/80 transition-colors">contacto@webstudioexpress.com</p>
                  </div>
                </div>

                <div className="flex items-start gap-4 group">
                  <Clock className="w-6 h-6 text-neon-pink flex-shrink-0 mt-1 group-hover:text-neon-pink/80 transition-colors" />
                  <div>
                    <h4 className="text-white font-medium mb-1">Horario</h4>
                    <p className="text-gray-400 group-hover:text-neon-pink/80 transition-colors">Lunes a Viernes: 9:00 AM - 6:00 PM</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-darkbg/70 p-8 rounded-xl border border-gray-800">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-2">
                    Nombre
                  </label>
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    required
                    value={formData.name}
                    onChange={handleChange}
                    className="bg-darkbg border-gray-800 text-white placeholder-gray-500 focus:border-neon-purple focus:ring-neon-purple"
                    placeholder="Tu nombre"
                  />
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-2">
                    Email
                  </label>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    className="bg-darkbg border-gray-800 text-white placeholder-gray-500 focus:border-neon-purple focus:ring-neon-purple"
                    placeholder="tu@email.com"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-300 mb-2">
                  Teléfono
                </label>
                <Input
                  id="phone"
                  name="phone"
                  type="tel"
                  required
                  value={formData.phone}
                  onChange={handleChange}
                  className="bg-darkbg border-gray-800 text-white placeholder-gray-500 focus:border-neon-purple focus:ring-neon-purple"
                  placeholder="Tu teléfono"
                />
              </div>

              <div>
                <label htmlFor="service" className="block text-sm font-medium text-gray-300 mb-2">
                  Servicio de interés
                </label>
                <Select name="service" required value={formData.service} onValueChange={(value) => setFormData(prev => ({ ...prev, service: value }))}>
                  <SelectTrigger className="bg-darkbg border-gray-800 text-white focus:border-neon-purple focus:ring-neon-purple">
                    <SelectValue placeholder="¿Qué servicio te interesa?" />
                  </SelectTrigger>
                  <SelectContent className="bg-darkbg border-gray-800">
                    <SelectItem value="web-design">Diseño de páginas web</SelectItem>
                    <SelectItem value="web-app">Diseño de aplicaciones web</SelectItem>
                    <SelectItem value="mobile-app">Diseño de aplicaciones para móviles</SelectItem>
                    <SelectItem value="marketing">Marketing para tu marca</SelectItem>
                    <SelectItem value="social-media">Diseño y Publicidad en redes Sociales</SelectItem>
                    <SelectItem value="other">Otros</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-300 mb-2">
                  Mensaje
                </label>
                <Textarea
                  id="message"
                  name="message"
                  required
                  value={formData.message}
                  onChange={handleChange}
                  className="bg-darkbg border-gray-800 text-white placeholder-gray-500 min-h-[150px] focus:border-neon-purple focus:ring-neon-purple"
                  placeholder="Cuéntanos sobre tu proyecto"
                />
              </div>

              <Button
                type="submit"
                disabled={isSubmitting}
                className="w-full bg-neon-purple/80 hover:bg-neon-purple text-white py-6 text-lg rounded-md shadow-lg shadow-neon-purple/20 transition-all duration-300 hover:shadow-neon-purple/40"
              >
                {isSubmitting ? 'Enviando...' : 'Enviar Mensaje'}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;

