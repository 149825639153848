import React from 'react';
import { Card } from '@/components/ui/card';
import { Lightbulb, Rocket, Zap, Smile, Timer, Shield } from 'lucide-react';

const BenefitsSection = () => {
  const benefits = [
    {
      title: "Soluciones Personalizadas",
      description: "Software construido específicamente para las necesidades únicas de tu negocio, no soluciones genéricas que intentan servir para todo.",
      icon: Lightbulb,
      color: "text-purple-400"
    },
    {
      title: "Ventaja Competitiva",
      description: "Destaca entre tus competidores con tecnología innovadora que diferencia tu negocio.",
      icon: Rocket,
      color: "text-cyan-400"
    },
    {
      title: "Mayor Eficiencia",
      description: "Automatiza tareas repetitivas y optimiza procesos para ahorrar tiempo y reducir costos operativos.",
      icon: Zap,
      color: "text-green-400"
    },
    {
      title: "Mejor Experiencia de Usuario",
      description: "Proporciona interfaces intuitivas y fluidas que encantan a tus clientes y los hacen volver.",
      icon: Smile,
      color: "text-pink-400"
    },
    {
      title: "Lanzamiento más Rápido",
      description: "Acelera los ciclos de desarrollo y lanza tus productos rápidamente con nuestra metodología ágil.",
      icon: Timer,
      color: "text-yellow-400"
    },
    {
      title: "Garantía de Calidad",
      description: "Pruebas rigurosas y control de calidad que aseguran un software confiable, seguro y de alto rendimiento.",
      icon: Shield,
      color: "text-purple-400"
    }
  ];

  return (
    <section id="benefits" className="py-20 relative overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-purple-400 via-pink-500 to-cyan-400 bg-clip-text text-transparent">
            ¿Por qué elegir Software Personalizado?
          </h2>
          <p className="text-muted-foreground max-w-2xl mx-auto">
            Invertir en software personalizado ofrece ventajas significativas que pueden transformar las operaciones de tu negocio.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {benefits.map((benefit, index) => (
            <Card 
              key={index} 
              className="p-6 bg-black/40 hover:bg-black/60 transition-colors border border-white/10 hover:border-white/20 group"
            >
              <div className="flex items-start gap-4">
                <div className={`p-3 rounded-xl bg-white/5 ${benefit.color} group-hover:scale-110 transition-transform`}>
                  <benefit.icon className="w-6 h-6" />
                </div>
                <div>
                  <h3 className="text-xl font-bold mb-3 bg-gradient-to-r from-white to-white/60 bg-clip-text text-transparent">
                    {benefit.title}
                  </h3>
                  <p className="text-muted-foreground">{benefit.description}</p>
                </div>
              </div>
            </Card>
          ))}
        </div>

        <div className="text-center mt-12">
          <p className="text-lg text-muted-foreground max-w-3xl mx-auto">
            Desbloquea el potencial de tu negocio con software hecho a medida que crece con las necesidades de tu empresa y se adapta a las demandas cambiantes del mercado.
          </p>
        </div>
      </div>
    </section>
  );
};

export default BenefitsSection;
