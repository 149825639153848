import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { works } from '@/components/sections/WorksSection';
import { Button } from '@/components/ui/button';
import { ArrowLeft } from 'lucide-react';

const WorkDetail = () => {
  const { id } = useParams<{ id: string }>();
  const work = works.find(w => w.id === Number(id));

  if (!work) {
    return (
      <div className="min-h-screen bg-[#0a0a0a] text-white flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-4xl font-bold mb-4">Trabajo no encontrado</h1>
          <Link to="/">
            <Button variant="outline" className="text-white border-white/20 hover:bg-white/10">
              <ArrowLeft className="mr-2 h-4 w-4" />
              Volver al inicio
            </Button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#0a0a0a] text-white pt-32">
      <div className="container mx-auto px-6">
        <Link to="/" className="inline-block mb-8">
          <Button variant="outline" className="text-white border-white/20 hover:bg-white/10">
            <ArrowLeft className="mr-2 h-4 w-4" />
            Volver
          </Button>
        </Link>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div className="space-y-8">
            <div>
              <h1 className="text-4xl font-bold mb-4">{work.title}</h1>
              <p className="text-xl text-gray-300">{work.description}</p>
            </div>

            <div className="space-y-4">
              <div>
                <h3 className="text-lg font-semibold text-neon-green mb-2">Cliente</h3>
                <div className="flex items-center gap-3">
                  <img 
                    src={work.clientLogo} 
                    alt={work.client} 
                    className="w-12 h-12 rounded-full border-2 border-white/20"
                  />
                  <span className="text-white">{work.client}</span>
                </div>
              </div>

              <div>
                <h3 className="text-lg font-semibold text-neon-green mb-2">Categoría</h3>
                <p className="text-white">{work.category}</p>
              </div>

              <div>
                <h3 className="text-lg font-semibold text-neon-green mb-2">Duración</h3>
                <p className="text-white">{work.timeframe}</p>
              </div>

              <div>
                <h3 className="text-lg font-semibold text-neon-green mb-2">Tecnologías</h3>
                <div className="flex flex-wrap gap-2">
                  {work.technologies.map((tech, index) => (
                    <span 
                      key={index}
                      className="px-3 py-1 bg-white/5 rounded-full text-sm text-white border border-white/10"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
              </div>

              <div>
                <h3 className="text-lg font-semibold text-neon-green mb-2">Enlace al Proyecto</h3>
                <a 
                  href={work.projectUrl} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-white hover:text-neon-green transition-colors"
                >
                  {work.projectUrl}
                </a>
              </div>
            </div>
          </div>

          <div className="space-y-8">
            <div className="rounded-xl overflow-hidden">
              <img 
                src={work.mainImage} 
                alt={work.title} 
                className="w-full h-auto object-cover"
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              {work.images.map((image, index) => (
                <div key={index} className="rounded-xl overflow-hidden">
                  <img 
                    src={image} 
                    alt={`${work.title} - Imagen ${index + 1}`} 
                    className="w-full h-48 object-cover"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkDetail;