import React from 'react';

const NeonLines = () => {
  return (
    <div className="neon-lines">
      <div className="neon-line horizontal" />
      <div className="neon-line horizontal" />
      <div className="neon-line horizontal" />
      <div className="neon-line vertical" />
      <div className="neon-line vertical" />
      <div className="neon-line vertical" />
    </div>
  );
};

export default NeonLines;
