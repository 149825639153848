
import React, { useEffect, useRef } from 'react';
import { cn } from '@/lib/utils';

const AboutUsSection: React.FC = () => {
  const sectionRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('revealed');
          }
        });
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section 
      id="about" 
      ref={sectionRef}
      className="section-reveal py-20 relative bg-darkbg/90"
    >
      <div className="container mx-auto px-6 z-10 relative">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            <span className="text-white">Sobre </span>
            <span className="text-neon-purple neon-text-purple">Nosotros</span>
          </h2>
        </div>
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div>
            <h3 className="text-2xl font-bold text-white mb-6">¡Bienvenidos a WebStudioExpress!</h3>
            <p className="text-gray-300 mb-6">
              Somos un equipo apasionado de desarrolladores, diseñadores y estrategas digitales dedicados a transformar ideas en soluciones tecnológicas excepcionales.
            </p>
            <p className="text-gray-300 mb-6">
              En WebStudioExpress, creemos que cada proyecto es único y merece una atención personalizada. Nos enorgullece trabajar estrechamente con nuestros clientes para entender sus necesidades específicas y crear soluciones que no solo satisfagan, sino que superen sus expectativas.
            </p>
            <p className="text-gray-300 mb-6">
              Lo que nos distingue es nuestro compromiso con la excelencia. A todos nuestros proyectos les agregamos <span className="font-bold text-neon-green">amor, pasión, enfoque y todo el corazón</span> con la finalidad de que el resultado alcance el objetivo del proyecto.
            </p>
            <p className="text-gray-300">
              Combinamos creatividad técnica con una estrategia sólida para asegurarnos de que su presencia digital no solo sea visualmente impactante, sino que también genere resultados tangibles para su negocio.
            </p>
          </div>
          
          <div className="relative">
            <div className="rounded-2xl overflow-hidden shadow-xl relative border border-gray-800 group">
              <div className="absolute inset-0 bg-gradient-to-br from-neon-purple/20 to-neon-cyan/20 opacity-80 group-hover:opacity-100 transition-opacity duration-500"></div>
              <img 
                src="https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?auto=format&fit=crop&w=1200&q=80" 
                alt="Desarrollador trabajando" 
                className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-darkbg to-transparent opacity-90"></div>
              <div className="absolute bottom-0 left-0 right-0 p-6">
                <p className="text-white text-lg font-bold">
                  Transformando visiones en realidades digitales
                </p>
              </div>
            </div>
            
            <div className="absolute -bottom-4 -right-4 w-24 h-24 bg-neon-purple/20 rounded-full blur-xl"></div>
            <div className="absolute -top-4 -left-4 w-32 h-32 bg-neon-cyan/20 rounded-full blur-xl"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;
