
import React, { useEffect, useRef } from 'react';
import { BadgeCheck, Code, Globe, Smartphone, Layout, ShieldCheck, Palette, Share2, TrendingUp } from 'lucide-react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';

const services = [
  {
    title: "Desarrollo Web Personalizado",
    description: "Aplicaciones web a medida construidas con tecnologías de vanguardia para satisfacer las necesidades específicas de tu negocio.",
    icon: Globe,
    iconColor: "text-neon-cyan"
  },
  {
    title: "Desarrollo de Aplicaciones Móviles",
    description: "Aplicaciones móviles nativas y multiplataforma para dispositivos iOS y Android con interfaces de usuario impresionantes.",
    icon: Smartphone,
    iconColor: "text-neon-purple"
  },
  {
    title: "Diseño UI/UX",
    description: "Diseño centrado en el usuario que mejora la experiencia y crea interfaces intuitivas para tus productos.",
    icon: Layout,
    iconColor: "text-neon-pink"
  },
  {
    title: "Desarrollo de APIs",
    description: "Soluciones de API robustas y escalables para conectar eficientemente tus aplicaciones y servicios.",
    icon: Code,
    iconColor: "text-neon-green"
  },
  {
    title: "Diseño Web",
    description: "Creación de sitios web atractivos y funcionales que destacan tu marca y optimizan la conversión de visitantes a clientes.",
    icon: Palette,
    iconColor: "text-neon-cyan"
  },
  {
    title: "Marketing Digital",
    description: "Estrategias de marketing online para aumentar la visibilidad de tu marca y generar leads cualificados para tu negocio.",
    icon: TrendingUp,
    iconColor: "text-neon-purple"
  },
  {
    title: "Publicidad en Redes Sociales",
    description: "Campañas publicitarias efectivas en plataformas sociales que conectan con tu audiencia objetivo y maximizan el ROI.",
    icon: Share2,
    iconColor: "text-neon-pink"
  },
  {
    title: "Consultoría de Software",
    description: "Asesoramiento experto sobre decisiones de arquitectura y stack tecnológico para tus proyectos.",
    icon: BadgeCheck,
    iconColor: "text-neon-green"
  },
  {
    title: "Seguridad y Mantenimiento",
    description: "Soporte continuo, actualizaciones de seguridad y mantenimiento para mantener tus aplicaciones funcionando sin problemas.",
    icon: ShieldCheck,
    iconColor: "text-neon-cyan"
  }
];

const ServicesSection: React.FC = () => {
  const sectionRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('revealed');
          }
        });
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section 
      id="services" 
      ref={sectionRef}
      className="section-reveal py-20 relative bg-darkbg/90"
    >
      <div className="container mx-auto px-6 z-10 relative">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            <span className="text-white">Nuestros </span>
            <span className="text-neon-pink neon-text-pink">Servicios</span>
          </h2>
          <p className="text-gray-300 max-w-2xl mx-auto">
            Ofrecemos servicios completos de desarrollo de software para dar vida a tus ideas con tecnologías de vanguardia.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <Card key={index} className="bg-darkbg/70 border border-gray-800 hover:border-neon-purple/50 transition-all group">
              <CardHeader>
                <div className="h-12 w-12 rounded-full bg-gray-800/50 flex items-center justify-center mb-4 group-hover:scale-110 transition-transform">
                  <service.icon className={`${service.iconColor} h-6 w-6`} />
                </div>
                <CardTitle className="text-white">{service.title}</CardTitle>
              </CardHeader>
              <CardContent>
                <CardDescription className="text-gray-300">
                  {service.description}
                </CardDescription>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
