import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { ArrowRight } from 'lucide-react';

// Datos de los trabajos (en un proyecto real, estos vendrían de una base de datos)
export const works = [
  {
    id: 1,
    title: "Tienda Virtual Fashionista",
    client: "Modas Exclusivas S.A.",
    clientLogo: "https://images.unsplash.com/photo-1511556532299-8f662fc26c06?auto=format&fit=crop&w=100&h=100&q=80",
    category: "e-Commerce",
    mainImage: "https://images.unsplash.com/photo-1491897554428-130a60dd4757?auto=format&fit=crop&w=1400&q=80",
    description: "Plataforma de comercio electrónico para una boutique de moda de lujo con sistema de gestión de inventario y pasarela de pagos integrada.",
    timeframe: "3 meses",
    projectUrl: "https://example.com/fashionista",
    images: [
      "https://images.unsplash.com/photo-1581092795360-fd1ca04f0952?auto=format&fit=crop&w=1400&q=80",
      "https://images.unsplash.com/photo-1582655346764-5d487f9c8a2c?auto=format&fit=crop&w=1400&q=80",
      "https://images.unsplash.com/photo-1571867424488-4565932edb41?auto=format&fit=crop&w=1400&q=80"
    ],
    technologies: ["React", "Node.js", "MongoDB", "Stripe"]
  },
  {
    id: 2,
    title: "Aplicación de Fitness Personal",
    client: "GymFit Pro",
    clientLogo: "https://images.unsplash.com/photo-1571019613454-1cb2f99b2d8b?auto=format&fit=crop&w=100&h=100&q=80",
    category: "Aplicación Móvil",
    mainImage: "https://images.unsplash.com/photo-1534258936925-c58bed479fcb?auto=format&fit=crop&w=1400&q=80",
    description: "Aplicación móvil de fitness que permite a los usuarios seguir sus rutinas de ejercicio, establecer metas y monitorear su progreso con análisis detallados.",
    timeframe: "4 meses",
    projectUrl: "https://example.com/gymfitpro",
    images: [
      "https://images.unsplash.com/photo-1605296867304-46d5465a13f1?auto=format&fit=crop&w=1400&q=80",
      "https://images.unsplash.com/photo-1592492152545-9695d3f473f4?auto=format&fit=crop&w=1400&q=80",
      "https://images.unsplash.com/photo-1609113160023-38f91900951b?auto=format&fit=crop&w=1400&q=80"
    ],
    technologies: ["React Native", "Firebase", "Redux", "Google Fit API"]
  },
  {
    id: 3,
    title: "Portal Educativo Interactivo",
    client: "EducaTec Internacional",
    clientLogo: "https://images.unsplash.com/photo-1503676260728-1c00da094a0b?auto=format&fit=crop&w=100&h=100&q=80",
    category: "Plataforma Educativa",
    mainImage: "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?auto=format&fit=crop&w=1400&q=80",
    description: "Plataforma de e-learning completa con cursos interactivos, evaluaciones automatizadas y seguimiento del progreso de los estudiantes.",
    timeframe: "6 meses",
    projectUrl: "https://example.com/educatec",
    images: [
      "https://images.unsplash.com/photo-1524995997946-a1c2e315a42f?auto=format&fit=crop&w=1400&q=80",
      "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?auto=format&fit=crop&w=1400&q=80",
      "https://images.unsplash.com/photo-1516321165247-4aa89a48be28?auto=format&fit=crop&w=1400&q=80"
    ],
    technologies: ["Angular", "Express.js", "PostgreSQL", "AWS"]
  },
  {
    id: 4,
    title: "Sistema de Gestión Inmobiliaria",
    client: "Propiedades Premium",
    clientLogo: "https://images.unsplash.com/photo-1560518883-ce09059eeffa?auto=format&fit=crop&w=100&h=100&q=80",
    category: "Software de Gestión",
    mainImage: "https://images.unsplash.com/photo-1560518883-3d5327cac07b?auto=format&fit=crop&w=1400&q=80",
    description: "Plataforma integral para agencias inmobiliarias que permite gestionar propiedades, clientes y transacciones con informes detallados y paneles analíticos.",
    timeframe: "5 meses",
    projectUrl: "https://example.com/premium-properties",
    images: [
      "https://images.unsplash.com/photo-1560518883-f5f0ba7f6b4b?auto=format&fit=crop&w=1400&q=80",
      "https://images.unsplash.com/photo-1560518883-f0e6a1ee7bc4?auto=format&fit=crop&w=1400&q=80",
      "https://images.unsplash.com/photo-1560518883-1e97df10be41?auto=format&fit=crop&w=1400&q=80"
    ],
    technologies: ["Vue.js", "Laravel", "MySQL", "Google Maps API"]
  },
  {
    id: 5,
    title: "App de Entrega de Comida",
    client: "DeliverEats",
    clientLogo: "https://images.unsplash.com/photo-1590846406792-0adc7f938f1d?auto=format&fit=crop&w=100&h=100&q=80",
    category: "Aplicación Móvil",
    mainImage: "https://images.unsplash.com/photo-1484659619207-9165d119dafe?auto=format&fit=crop&w=1400&q=80",
    description: "Aplicación móvil para pedidos de comida a domicilio con seguimiento en tiempo real, múltiples métodos de pago y sistema de reseñas.",
    timeframe: "4 meses",
    projectUrl: "https://example.com/delivereats",
    images: [
      "https://images.unsplash.com/photo-1565299624946-b28f40a0ae38?auto=format&fit=crop&w=1400&q=80",
      "https://images.unsplash.com/photo-1565299585323-38d6b0865b47?auto=format&fit=crop&w=1400&q=80",
      "https://images.unsplash.com/photo-1550951957-3ab761159b8e?auto=format&fit=crop&w=1400&q=80"
    ],
    technologies: ["Flutter", "Node.js", "MongoDB", "Google Maps Platform"]
  },
  {
    id: 6,
    title: "Dashboard Corporativo Financiero",
    client: "InversFinance Corp",
    clientLogo: "https://images.unsplash.com/photo-1611095790444-1dfa35e37b52?auto=format&fit=crop&w=100&h=100&q=80",
    category: "Software Financiero",
    mainImage: "https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&w=1400&q=80",
    description: "Plataforma de visualización de datos financieros con análisis predictivo, generación de informes automáticos y panel de control personalizable.",
    timeframe: "7 meses",
    projectUrl: "https://example.com/inversfinance",
    images: [
      "https://images.unsplash.com/photo-1543286386-713bdd548da4?auto=format&fit=crop&w=1400&q=80",
      "https://images.unsplash.com/photo-1551288049-bebda4e38f71?auto=format&fit=crop&w=1400&q=80",
      "https://images.unsplash.com/photo-1620228922597-cae806a3cf00?auto=format&fit=crop&w=1400&q=80"
    ],
    technologies: ["React", "D3.js", "Python", "PostgreSQL"]
  },
  {
    id: 7,
    title: "Plataforma de Telemedicina",
    client: "SaludConnect",
    clientLogo: "https://images.unsplash.com/photo-1579684385127-1ef15d508118?auto=format&fit=crop&w=100&h=100&q=80",
    category: "Aplicación Web",
    mainImage: "https://images.unsplash.com/photo-1576091160550-2173dba999ef?auto=format&fit=crop&w=1400&q=80",
    description: "Sistema integral de telemedicina que permite consultas virtuales, gestión de historias clínicas y seguimiento de pacientes.",
    timeframe: "8 meses",
    projectUrl: "https://example.com/saludconnect",
    images: [
      "https://images.unsplash.com/photo-1631217873736-9b6b1113a845?auto=format&fit=crop&w=1400&q=80",
      "https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?auto=format&fit=crop&w=1400&q=80",
      "https://images.unsplash.com/photo-1625134673679-eabf2536583d?auto=format&fit=crop&w=1400&q=80"
    ],
    technologies: ["Vue.js", "WebRTC", "Express.js", "MongoDB"]
  },
  {
    id: 8,
    title: "Aplicación de Gestión Agrícola",
    client: "AgroTech Solutions",
    clientLogo: "https://images.unsplash.com/photo-1560493676-04071c5f467b?auto=format&fit=crop&w=100&h=100&q=80",
    category: "Software de Gestión",
    mainImage: "https://images.unsplash.com/photo-1625246333195-78d9c38ad449?auto=format&fit=crop&w=1400&q=80",
    description: "Sistema de gestión agrícola con monitoreo de cultivos, análisis de datos climáticos y optimización de recursos.",
    timeframe: "6 meses",
    projectUrl: "https://example.com/agrotech",
    images: [
      "https://images.unsplash.com/photo-1592982573332-d8c0e84a1659?auto=format&fit=crop&w=1400&q=80",
      "https://images.unsplash.com/photo-1628352081506-83c43123ed5d?auto=format&fit=crop&w=1400&q=80",
      "https://images.unsplash.com/photo-1595841696677-6489ff3f8cd1?auto=format&fit=crop&w=1400&q=80"
    ],
    technologies: ["React", "IoT", "Python", "TensorFlow"]
  },
  {
    id: 9,
    title: "Red Social para Artistas",
    client: "ArtConnect",
    clientLogo: "https://images.unsplash.com/photo-1503342217505-b0a15ec3261c?auto=format&fit=crop&w=100&h=100&q=80",
    category: "Red Social",
    mainImage: "https://images.unsplash.com/photo-1513364776144-60967b0f800f?auto=format&fit=crop&w=1400&q=80",
    description: "Plataforma social para que artistas compartan su trabajo, conecten con fans y vendan su arte con herramientas de promoción integradas.",
    timeframe: "7 meses",
    projectUrl: "https://example.com/artconnect",
    images: [
      "https://images.unsplash.com/photo-1571498664957-faf34801c3b1?auto=format&fit=crop&w=1400&q=80",
      "https://images.unsplash.com/photo-1510832842230-87380d91523f?auto=format&fit=crop&w=1400&q=80",
      "https://images.unsplash.com/photo-1536924430914-91f9e2041b83?auto=format&fit=crop&w=1400&q=80"
    ],
    technologies: ["Next.js", "GraphQL", "PostgreSQL", "Stripe"]
  },
  {
    id: 10,
    title: "Sistema de Reservas para Restaurantes",
    client: "Gourmet Reservations",
    clientLogo: "https://images.unsplash.com/photo-1555396273-367ea4eb4db5?auto=format&fit=crop&w=100&h=100&q=80",
    category: "Aplicación Web",
    mainImage: "https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?auto=format&fit=crop&w=1400&q=80",
    description: "Sistema completo de reservas para restaurantes con gestión de mesas, menús digitales y análisis de patrones de clientes.",
    timeframe: "5 meses",
    projectUrl: "https://example.com/gourmet",
    images: [
      "https://images.unsplash.com/photo-1414235077428-338989a2e8c0?auto=format&fit=crop&w=1400&q=80",
      "https://images.unsplash.com/photo-1552566626-52f8b828add9?auto=format&fit=crop&w=1400&q=80",
      "https://images.unsplash.com/photo-1550966871-3ed3cdb5ed0c?auto=format&fit=crop&w=1400&q=80"
    ],
    technologies: ["React", "Firebase", "Node.js", "Twilio API"]
  }
];

const WorksSection: React.FC = () => {
  const sectionRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('revealed');
          }
        });
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section 
      id="works" 
      ref={sectionRef}
      className="section-reveal py-20 relative bg-darkbg/90"
    >
      <div className="container mx-auto px-6 z-10 relative">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            <span className="text-white">Nuestros </span>
            <span className="text-neon-green neon-text-green">Trabajos</span>
          </h2>
          <p className="text-gray-300 max-w-2xl mx-auto">
            Explora algunos de nuestros proyectos más destacados y descubre cómo hemos ayudado a empresas a transformar sus ideas en soluciones digitales.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {works.map((work) => (
            <Link 
              to={`/work/${work.id}`} 
              key={work.id}
              className="group bg-darkbg/70 border border-gray-800 rounded-xl overflow-hidden hover:border-neon-green/50 transition-all hover:scale-[1.02]"
            >
              <div className="relative h-64 overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-t from-darkbg/90 to-transparent z-10"></div>
                <img 
                  src={work.mainImage} 
                  alt={work.title} 
                  className="absolute inset-0 w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                />
                <div className="absolute bottom-4 left-4 z-20 flex items-center gap-2">
                  <img 
                    src={work.clientLogo} 
                    alt={work.client} 
                    className="w-10 h-10 rounded-full border-2 border-white/20"
                  />
                  <span className="text-white text-sm font-medium">{work.client}</span>
                </div>
              </div>
              
              <div className="p-6">
                <div className="flex justify-between items-start mb-3">
                  <h3 className="text-xl font-bold text-white group-hover:text-neon-green transition-colors">{work.title}</h3>
                  <span className="px-2 py-1 bg-gray-800 text-neon-cyan text-xs rounded-full">{work.category}</span>
                </div>
                
                <p className="text-gray-400 text-sm mb-4 line-clamp-2">{work.description}</p>
                
                <div className="flex items-center justify-between pt-3 border-t border-gray-800">
                  <span className="text-gray-500 text-sm">
                    Duración: <span className="text-gray-300">{work.timeframe}</span>
                  </span>
                  <span className="text-neon-green flex items-center group-hover:translate-x-1 transition-transform">
                    Ver Detalles <ArrowRight className="ml-1 w-4 h-4" />
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </div>
        
        <div className="mt-12 text-center">
          <Button 
            className="bg-neon-green/80 hover:bg-neon-green text-white px-8 py-6 text-lg rounded-md shadow-lg shadow-neon-green/20"
            onClick={() => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })}
          >
            ¿Listo para transformar tu idea en realidad?
          </Button>
        </div>
      </div>
    </section>
  );
};

export default WorksSection;

