import React from 'react';

const TestimonialsSection = () => {
  const testimonials = [
    {
      name: "Carlos Mendoza",
      videoUrl: "https://www.tiktok.com/@macweb.digital/video/7439138533270474040",
      embedId: "7439138533270474040"
    },
    {
      name: "Luis Fernandez",
      videoUrl: "https://www.tiktok.com/@sabri.ci.multimedia/video/7215747977166523653",
      embedId: "7215747977166523653"
    },
    {
      name: "Roberto Alvarado",
      videoUrl: "https://www.tiktok.com/@macweb.digital/video/7432447196852800773",
      embedId: "7432447196852800773"
    }
  ];

  return (
    <section id="testimonials" className="py-20 relative overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Lo que dicen nuestros clientes</h2>
          <p className="text-muted-foreground max-w-2xl mx-auto">
            Descubre cómo hemos ayudado a otros emprendedores y empresas a alcanzar sus objetivos.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="relative">
              {/* iPhone 16 Pro Max Frame */}
              <div className="relative mx-auto w-[320px] h-[690px] rounded-[55px] p-4 overflow-hidden"
                   style={{
                     borderWidth: '2px',
                     borderStyle: 'solid',
                     borderImage: 'linear-gradient(45deg, #00ffff, #00ff00, #39ff14) 1'
                   }}>
                {/* Dynamic Island */}
                <div className="absolute top-0 left-1/2 -translate-x-1/2 w-[120px] h-[35px] bg-black rounded-b-[20px] z-10"></div>
                
                {/* TikTok Embed */}
                <iframe
                  src={`https://www.tiktok.com/embed/v2/${testimonial.embedId}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    borderRadius: '40px'
                  }}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <h3 className="text-center mt-4 font-medium">{testimonial.name}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
