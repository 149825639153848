import React from 'react';
import { cn } from '@/lib/utils';

interface LogoProps extends React.HTMLAttributes<HTMLDivElement> {}

const Logo: React.FC<LogoProps> = ({ className, ...props }) => {
  return (
    <div className={cn('flex items-center', className)} {...props}>
      <img 
        src="/images/Logo-webstudioexpress Blanco.png" 
        alt="WebStudioExpress Logo" 
        className="h-12 w-auto object-contain"
      />
    </div>
  );
};

export default Logo;
