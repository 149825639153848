
import React, { useEffect, useRef } from 'react';
import { cn } from '@/lib/utils';
import { Check, ChevronRight, Code, FileSearch, Lightbulb, Rocket } from 'lucide-react';

const processSteps = [
  {
    id: 1,
    title: "Descubrimiento",
    description: "Comenzamos entendiendo tus objetivos, audiencia y desafíos específicos del proyecto. Esta fase establece las bases para una solución personalizada que responda perfectamente a tus necesidades.",
    icon: <Lightbulb className="h-10 w-10 text-neon-purple" />,
    image: "https://images.unsplash.com/photo-1558403194-611308249627?auto=format&fit=crop&w=800&q=80"
  },
  {
    id: 2,
    title: "Planificación",
    description: "Desarrollamos una estrategia detallada y una hoja de ruta clara para tu proyecto. Definimos tecnologías, plazos y entregables que guiarán todo el proceso de desarrollo.",
    icon: <FileSearch className="h-10 w-10 text-neon-cyan" />,
    image: "https://images.unsplash.com/photo-1531403009284-440f080d1e12?auto=format&fit=crop&w=800&q=80"
  },
  {
    id: 3,
    title: "Diseño y Desarrollo",
    description: "Nuestro equipo da vida a tu visión, creando interfaces atractivas y funcionalidades robustas. Mantenemos una comunicación constante con actualizaciones regulares sobre el progreso.",
    icon: <Code className="h-10 w-10 text-neon-green" />,
    image: "https://images.unsplash.com/photo-1581092580497-e0d23cbdf1dc?auto=format&fit=crop&w=800&q=80"
  },
  {
    id: 4,
    title: "Pruebas",
    description: "Realizamos pruebas exhaustivas para garantizar que tu solución funcione perfectamente en todos los dispositivos y navegadores, ofreciendo una experiencia de usuario óptima.",
    icon: <Check className="h-10 w-10 text-neon-pink" />,
    image: "https://images.unsplash.com/photo-1551434678-e076c223a692?auto=format&fit=crop&w=800&q=80"
  },
  {
    id: 5,
    title: "Lanzamiento",
    description: "Implementamos tu solución con un plan de lanzamiento cuidadosamente orquestado, asegurando una transición sin problemas y minimizando cualquier interrupción.",
    icon: <Rocket className="h-10 w-10 text-neon-cyan" />,
    image: "https://images.unsplash.com/photo-1552664730-d307ca884978?auto=format&fit=crop&w=800&q=80"
  }
];

const OurProcessSection: React.FC = () => {
  const sectionRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('revealed');
          }
        });
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section 
      id="process" 
      ref={sectionRef}
      className="section-reveal py-20 relative"
    >
      <div className="container mx-auto px-6 z-10 relative">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            <span className="text-white">Nuestro </span>
            <span className="text-neon-cyan neon-text-cyan">Proceso</span>
          </h2>
          <p className="text-gray-300 max-w-2xl mx-auto">
            Un enfoque estructurado y transparente que garantiza resultados excepcionales para cada proyecto.
          </p>
        </div>
        
        <div className="relative">
          {/* Timeline line */}
          <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-gradient-to-b from-neon-purple via-neon-cyan to-neon-green hidden md:block"></div>
          
          {processSteps.map((step, index) => (
            <div 
              key={step.id} 
              className={cn(
                "mb-16 last:mb-0 relative",
                "group"
              )}
            >
              <div className={cn(
                "flex flex-col md:flex-row gap-8 items-center",
                index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
              )}>
                {/* Step image */}
                <div className="w-full md:w-5/12">
                  <div className="relative rounded-2xl overflow-hidden border border-gray-800 shadow-lg group-hover:border-neon-cyan/50 transition-all duration-300">
                    <div className="absolute inset-0 bg-gradient-to-br from-neon-purple/20 to-neon-cyan/20 opacity-70 group-hover:opacity-100 transition-opacity duration-500"></div>
                    <img 
                      src={step.image} 
                      alt={step.title} 
                      className="w-full h-64 object-cover transition-transform duration-500 group-hover:scale-105"
                    />
                  </div>
                </div>
                
                {/* Timeline circle for desktop */}
                <div className="absolute left-1/2 transform -translate-x-1/2 hidden md:flex items-center justify-center">
                  <div className="w-12 h-12 rounded-full bg-darkbg border-4 border-neon-cyan flex items-center justify-center z-10 shadow-lg shadow-neon-cyan/20">
                    <span className="text-white font-bold">{step.id}</span>
                  </div>
                </div>
                
                {/* Step content */}
                <div className="w-full md:w-5/12 bg-darkbg/80 backdrop-blur-sm p-8 rounded-2xl border border-gray-800 group-hover:border-neon-cyan/50 transition-all duration-300">
                  <div className="flex items-center gap-4 mb-4">
                    <div className="p-3 rounded-xl bg-darkbg/80 border border-gray-700">
                      {step.icon}
                    </div>
                    <h3 className="text-2xl font-bold text-white">{step.title}</h3>
                  </div>
                  <p className="text-gray-300">{step.description}</p>
                  
                  {/* Connector for mobile view */}
                  {index < processSteps.length - 1 && (
                    <div className="flex justify-center mt-6 md:hidden">
                      <ChevronRight className="w-6 h-6 text-neon-cyan transform rotate-90" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurProcessSection;
