import React from 'react';
import Navbar from '@/components/Navbar';
import NeonLines from '@/components/NeonLines';
import HeroSection from '@/components/sections/HeroSection';
import AboutUsSection from '@/components/sections/AboutUsSection';
import ServicesSection from '@/components/sections/ServicesSection';
import BenefitsSection from '@/components/sections/BenefitsSection';
import OurProcessSection from '@/components/sections/OurProcessSection';
import WorksSection from '@/components/sections/WorksSection';
import TestimonialsSection from '@/components/sections/TestimonialsSection';
import ContactSection from '@/components/sections/ContactSection';
import WhatsAppButton from '@/components/WhatsAppButton';
import Footer from '@/components/Footer';

const Index = () => {
  return (
    <div className="min-h-screen bg-[#0a0a0a]">
      <NeonLines />
      <div className="content-wrapper">
        <Navbar />
        <main>
          <HeroSection />
          <AboutUsSection />
          <ServicesSection />
          <BenefitsSection />
          <OurProcessSection />
          <WorksSection />
          <TestimonialsSection />
          <ContactSection />
        </main>
        <Footer />
        <WhatsAppButton phoneNumber="+522218628551" />
      </div>
    </div>
  );
};

export default Index;