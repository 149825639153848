import React from 'react';
import Logo from './Logo';
import { Facebook, Twitter, Instagram, Linkedin, Github } from 'lucide-react';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="bg-[#0a0a0a] pt-16 border-t border-gray-800 relative z-10">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12 mb-12">
          <div className="md:col-span-1">
            <Logo className="h-12 mb-4" />
            <p className="text-gray-300 mb-6">
              Creando soluciones innovadoras de software para transformar tu presencia digital.
            </p>
            <div className="flex space-x-4">
              <a href="#" className="text-gray-400 hover:text-neon-purple transition-colors">
                <Facebook size={20} />
              </a>
              <a href="#" className="text-gray-400 hover:text-neon-purple transition-colors">
                <Twitter size={20} />
              </a>
              <a href="#" className="text-gray-400 hover:text-neon-purple transition-colors">
                <Instagram size={20} />
              </a>
              <a href="#" className="text-gray-400 hover:text-neon-purple transition-colors">
                <Linkedin size={20} />
              </a>
              <a href="#" className="text-gray-400 hover:text-neon-purple transition-colors">
                <Github size={20} />
              </a>
            </div>
          </div>
          
          <div>
            <h3 className="text-white font-semibold mb-6">Servicios</h3>
            <ul className="space-y-3">
              <li><a href="#services" className="text-gray-300 hover:text-neon-purple transition-colors">Desarrollo Web</a></li>
              <li><a href="#services" className="text-gray-300 hover:text-neon-purple transition-colors">Aplicaciones Móviles</a></li>
              <li><a href="#services" className="text-gray-300 hover:text-neon-purple transition-colors">Diseño UI/UX</a></li>
              <li><a href="#services" className="text-gray-300 hover:text-neon-purple transition-colors">Desarrollo de APIs</a></li>
              <li><a href="#services" className="text-gray-300 hover:text-neon-purple transition-colors">Consultoría</a></li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-white font-semibold mb-6">Empresa</h3>
            <ul className="space-y-3">
              <li><a href="#home" className="text-gray-300 hover:text-neon-purple transition-colors">Sobre Nosotros</a></li>
              <li><a href="#benefits" className="text-gray-300 hover:text-neon-purple transition-colors">Nuestro Enfoque</a></li>
              <li><a href="#portfolio" className="text-gray-300 hover:text-neon-purple transition-colors">Portafolio</a></li>
              <li><a href="#contact" className="text-gray-300 hover:text-neon-purple transition-colors">Contacto</a></li>
              <li><a href="#" className="text-gray-300 hover:text-neon-purple transition-colors">Carreras</a></li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-white font-semibold mb-6">Legal</h3>
            <ul className="space-y-3">
              <li><a href="#" className="text-gray-300 hover:text-neon-purple transition-colors">Política de Privacidad</a></li>
              <li><a href="#" className="text-gray-300 hover:text-neon-purple transition-colors">Términos de Servicio</a></li>
              <li><a href="#" className="text-gray-300 hover:text-neon-purple transition-colors">Política de Cookies</a></li>
            </ul>
          </div>
        </div>
        
        <div className="py-6 border-t border-gray-800 text-center">
          <p className="text-gray-400">
            © {currentYear} WebStudioExpress. Todos los derechos reservados.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
